<template>
	<div style="position:absolute;z-index:10;top:10px;left:10px;">
		<el-radio-group size="mini" v-model="setMapMode">
			<el-radio-button label="地图"></el-radio-button>
			<el-radio-button label="混合"></el-radio-button>
		</el-radio-group>
	</div>
</template>

<script>
export default {
	name: 'FrontendPcBigdataWeixingPlugin',
	props:{
		map:function(){
			return null
		}
	},

	data() {
		return {
			setMapMode:'地图'
		};
	},

	mounted() {

	},

	watch: {
    setMapMode(mapmodename) {
      if (mapmodename === '混合') {
        this.map.setMapType(BMAP_SATELLITE_MAP) //卫星地图
      } else {
        this.map.setMapType(BMAP_NORMAL_MAP)//正常地图
      }
    }
  },


};
</script>