<template>
  <div class="maincon">
    <div class="bmap-page-container">

      <el-bmap vid="bmapDemo" :bmap-manager="bmapManager" :zoom="zoom" :center="center" :events="events"
        class="bmap-demo">
        <weixing-plugin :map="map"></weixing-plugin>

        <el-bmap-polygon :v-if="polygons.length" v-for="(polygon, index) in polygons" :visible="visible" :key="index"
          :vid="index" :ref="`polygon_${index}`" :path="polygon.path" :storeColor="polygon.storeColor"
          :fillOpacity="polygon.fillOpacity" :fillColor="polygon.fillColor" :strokeStyle="polygon.strokeStyle"
          :enable-editing="polygon.enableEditing" :events="polygon.events"></el-bmap-polygon>


        <el-bmap-label v-for="item in pointsList" :position="[item.lng_lat.lng, item.lng_lat.lat]"
          :visible="item.visible" 
          :offset="[-23,-50]"
          :is-custom="true">
          <div class="labelbox" style="color:#fff;font-size:20px;">

            <div class="labelcontent">
              <img class="iconimg" :src="item.imgurl" />
              <div class="labeltext">
                {{ item.name || '空' }}
              </div>
            </div>
            <span class="labeldown" style="color:#000"></span>
          </div>
        </el-bmap-label>


      </el-bmap>
    </div>

  </div>
</template>

<script>
import weixingPlugin from '@/components/bmap/weixingPlugin.vue'
import VueBMap from "vue-bmap-gl";
import { pointData, polygonData } from '@/api/cityresearch'


let bmapManager = new VueBMap.BMapManager();

const icontype1 = '@/assets/icon/wenwu.png'
const icontype2 = '@/assets/icon/gaoya.png'


export default {
  components: { weixingPlugin },
  props: {
    cname: String,
    city_plate_id: {
      type: Number,
    },
    cate: {
      type: Number,
      default: 1
    },//商业 2产业
  },
  computed: {
    currentArea: {
      get() {
        return this.$store.state.currentArea
      }
    }
  },

  watch: {
    cname(v) {
      let myGeo = new BMapGL.Geocoder();
      var that = this
      myGeo.getPoint(v + '人民政府', function (point) {
        if (point) {
          that.map.centerAndZoom(point)
        } else {
          alert('您选择的地址没有解析到结果！');
        }
      }, v + '市')
      this.setAreaLine(v)
      this.getPointData()
    },
    city_plate_id(v) {
      this.getPointData()
    }
  },
  mounted() {
    this.getPointData()
    setTimeout(function () {
      // console.log('mounted', BMapGL)
    }, 3000)

  },
  data() {
    return {

      blockoverlay: {},
      area: '',
      pointsList: [
        {
          id: 1,
          name: '这是标题1',
          lng_lat: {
            lat: '1',
            lng: '1'
          },
          imgurl: ''
        }
      ],
      visible: true,
      polygons: [],
      map: {},
      bmapManager,
      geodata: "",
      events: {
        init: (o) => {
          this.map = o
          let currentname = this.cname
          let myGeo = new BMapGL.Geocoder();
          // 将地址解析结果显示在地图上，并调整地图视野
          let that = this;
          myGeo.getPoint(currentname + '市人民政府', function (point) {
            if (point) {
              o.centerAndZoom(point, 10);
              // o.addOverlay(new BMapGL.Marker(point, {title: ' '}))
              that.setAreaLine(currentname)
            } else {
              alert('您选择的地址没有解析到结果！');
            }
          }, currentname + '市')

          // 将地址解析结果显示在地图上，并调整地图视野
          that.getPointData()

        },
      },
      count: 1,
      center: [0, 0],
      zoom: 12
    };
  },
  methods: {

    handleImg(data) {
      const res = data.map(item => {
        item.imgurl = require('@/assets/icon/icontype' + item.type + '.png')
        return item
      })
      return res
    },

    //获取点数据
    getPointData() {
      const params = {
        city_plate_id: this.city_plate_id,
        type: this.cate
      }
      if (this.city_plate_id) {
        pointData(params).then(res => {
          // console.log('===', res)
          this.pointsList = this.handleImg(res.data)

        })
      }


    },



    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays()// 添加覆盖物

      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 2,
        // strokeColor: obj.strokecolor
        strokeStyle: 'dashed',
        fillColor: '#dedede',
        strokeColor: '#000000'
      }) // 建立多边形覆盖物
      // console.log('===ply==', ply)
      // this.overlaycompleteByBD(ply)
      this.blockoverlay = ply

      this.map.addOverlay(ply) // 添加覆盖物
      this.map.setViewport(ply.getPath()) // 调整视野
    },



    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data) // 获取地图数据数组
      dataArr.map(obj => {
        this.drawPloygonByBD(obj)
      })
    },

    getPolygonData() {
      const params = {
        city_plate_id: this.currentArea,
        type: 10
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        polygondata.map(item => {
          this.handlerData(item.set)
        })

      })
    },


    setAreaLine(cname) {
      if (cname) {

        var bdary = new BMapGL.Boundary();
        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。 
          that.map.removeOverlay(that.blockoverlay)
          that.polygons = []
          if (rs.boundaries.length === 0) { //自定义区域画多变形
            //清除原来的多边形
            that.getPolygonData()

          } else {
            const newbounderies = rs.boundaries.map(item => {
              let single = item.split(';');
              let newItem = single.map(v => {
                return v.split(',')
              })
              const object = {
                // strokeColor: '#ff000',
                strokeStyle: 'dashed',
                // fillOpacity: 0,
                path: newItem,
                // fillColor: '#000000'
              }

              return object;
            })
            console.log('newbounderies', newbounderies)

            that.polygons = newbounderies;
          }
        });
      }

    },



  }


};
</script> 
<style lang="scss" scoped>
.maincon {
  position: relative;
  height: calc(100vh - 220px);
}

.contrl {
  position: absolute;
  ;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 220px);
  z-index: 9999
}

.bmap-demo {
  height: calc(100vh - 240px);
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: flex-start;

  border-radius: 5px;

  .labelcontent {
    // box-shadow: ;
    box-shadow: 0px 5px 26px 0px rgba(0,0,0,0.2);
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    margin-left:15px;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;

  }
}
</style>