<template>
  <div class="cityLocation">
    <city-list  @selectedCity="selectedCid"></city-list>
    <div class="areaMap">
      <leaflet-ye :cname="cname" :city_plate_id="currentArea"  cate="1"></leaflet-ye>
    </div>
   
  </div>
</template>
<script>
import CityList from '@/components/cityreserch/CityList.vue'
import Ye from '@/components/bmap/Ye.vue'
import LeafletYe from '@/components/leaflet/leafletYe.vue'
import {cityLocationImg} from "@/api/cityresearch"
// import VueBMap from 'vue-mapvgl'
export default {
  components: {CityList ,LeafletYe},
  data(){
  
      return {
      imglist:[]
    }
  },
  computed:{
    cname:{
      get(){
        let a =  this.$store.state.cname 
        console.log('a',a,'b')
        return a
      },
      set(v){
       this.$store.commit('changeCname', v)
      }
    },
    //当前选择的id
    currentArea:{
      get(){
        return this.$store.state.currentArea
      }
    }
  },
  watch:{
    currentArea(newv){
      
      this.cname = this.$store.state.cname
      
      this.getImgList()

    }
  },
  mounted(){
      this.cname = this.$store.state.cname
      this.getImgList()
  },
  methods:{
    selectedCid(cid){
      this.$store.state.currentCity.filter(item=>{
        if(item.id === cid){
          this.$store.commit('changeCname',item.label)
        }
      })
    },
    getImgList(){
      cityLocationImg({city_plate_id:this.currentArea}).then(res=>{
        console.log('imglist',res.data)
        this.imglist = res.data
      })
      // this.imglist = [];

    }

  }
}
</script>
<style lang="scss">
  .cityLocation{
    width:100%;
    height:calc(100vh - 100px);
    color:#333;
  }
  .areaMap{
    width:98%;
    margin:1%;
    height:calc(100vh - 284px);
    background:#ddd;
  }
  .swipercontainer{
    position: relative;
    bottom: 0;
    right:0;
    left:0;
    top:-100%;
    z-index:10;
  }
</style>